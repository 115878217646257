<template>
<v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
  <v-main >
    <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
      
       <v-tabs
       class="mt-3"
       color="#4CAF50"
                  left
                >
                  <v-tab>General</v-tab>
                  <!-- <v-tab>Custom Links</v-tab> -->
                  <!-- <v-tab>Letters</v-tab> -->
                  <!-- <v-tab>Grant Settings</v-tab> -->
                  <v-tab-item
                  style="background-color: transparent!important;"
                    :key="0"
                  >
        <v-row
            align="center"
            justify="center"
            class=" mb-5"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-6" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                       
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Settings</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <span style="color:#5D6167;">Sales Representative</span>
  

                      <v-select
                      label="Display to"
                        v-model="settings.sales_rep_flag"
                        :items="['None','All','Custom']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="settings.sales_rep_flag == 'Custom'"
                        v-model="settings.sales_rep_accounts"
                        :items="companies"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Select Users"
                        multiple
                      ></v-select>
                    
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <span style="color:#5D6167;">Referral Representative</span>
  
                      <v-select
                      label="Display to"
                        v-model="settings.ref_rep_flag"
                        :items="['None','All','Custom']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="settings.ref_rep_flag == 'Custom'"
                        v-model="settings.ref_rep_accounts"
                        :items="companies"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Select Users"
                        multiple
                      ></v-select>
                    
                    </v-col>
                       <!-- <v-col
                      cols="12"
                      md="6"
                    >
                    
                        <v-text-field
                            color="#e91e63"
                            label="Grant Max Count"
                            ref="name"
                        v-model="settings.grant_count"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col> -->
                      <v-col
                      cols="12"
                      md="6"
                    >
                  <v-select
                            item-text="name"
                        item-value="id"
                        label="Payment Gateway"
                        class="font-size-input input-style"
                            placeholder="Select payment gateway"
                            v-model="settings.payment_gateway"
                            :items="payment_gateway_options"
                            ></v-select>
                    
                    </v-col>
                      <v-col
                      cols="12"
                      md="6"
                    >
                    <p style="text-align:left;">Payment Mode</p>
                    <v-switch
                        v-model="settings.test_mode"
                        label="Test"
                        style="margin-bottom: 30px;"
                        color="green"
                        value="1"
                        inset
                      >
                      <template #prepend>
                        <v-label>Live</v-label>
                      </template>
                      </v-switch>
                    
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">1st Referral</p>
                    <v-select
                            item-text="name"
                        item-value="id"
                        label="Type"
                        class="font-size-input input-style"
                            placeholder="Select payment gateway"
                            v-model="settings.first_ref_type"
                            :items="['Value','Percent']"
                            ></v-select>
                            
                    
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">&nbsp;</p>
                    <v-text-field
                                v-if="settings.first_ref_type == 'Value'"
                                v-model="settings.first_ref_value"
                                prefix="$"
                                :label="'Amount'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                              <v-text-field
                                v-if="settings.first_ref_type == 'Percent'"
                                v-model="settings.first_ref_value"
                                prefix="%"
                                :label="'Percent'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                      </v-col>

                      <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">2nd Referral</p>
                    <v-select
                            item-text="name"
                        item-value="id"
                        label="Type"
                        class="font-size-input input-style"
                            placeholder="Select payment gateway"
                            v-model="settings.second_ref_type"
                            :items="['Value','Percent']"
                            ></v-select>
                            
                    
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">&nbsp;</p>
                    <v-text-field
                                v-if="settings.second_ref_type == 'Value'"
                                v-model="settings.second_ref_value"
                                prefix="$"
                                :label="'Amount'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                              <v-text-field
                                v-if="settings.second_ref_type == 'Percent'"
                                v-model="settings.second_ref_value"
                                prefix="%"
                                :label="'Percent'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                      </v-col>

                      <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">3rd Referral</p>
                    <v-select
                            item-text="name"
                        item-value="id"
                        label="Type"
                        class="font-size-input input-style"
                            placeholder="Select payment gateway"
                            v-model="settings.third_ref_type"
                            :items="['Value','Percent']"
                            ></v-select>
                            
                    
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">&nbsp;</p>
                    <v-text-field
                                v-if="settings.third_ref_type == 'Value'"
                                v-model="settings.third_ref_value"
                                prefix="$"
                                :label="'Amount'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                              <v-text-field
                                v-if="settings.third_ref_type == 'Percent'"
                                v-model="settings.third_ref_value"
                                prefix="%"
                                :label="'Percent'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                      </v-col>


                      <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">4th Referral</p>
                    <v-select
                            item-text="name"
                        item-value="id"
                        label="Type"
                        class="font-size-input input-style"
                            placeholder="Select payment gateway"
                            v-model="settings.forth_ref_type"
                            :items="['Value','Percent']"
                            ></v-select>
                            
                    
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">&nbsp;</p>
                    <v-text-field
                                v-if="settings.forth_ref_type == 'Value'"
                                v-model="settings.forth_ref_value"
                                prefix="$"
                                :label="'Amount'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                              <v-text-field
                                v-if="settings.forth_ref_type == 'Percent'"
                                v-model="settings.forth_ref_value"
                                prefix="%"
                                :label="'Percent'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">5th Referral</p>
                    <v-select
                            item-text="name"
                        item-value="id"
                        label="Type"
                        class="font-size-input input-style"
                            placeholder="Select payment gateway"
                            v-model="settings.fifth_ref_type"
                            :items="['Value','Percent']"
                            ></v-select>
                            
                    
                  </v-col>
                  <v-col
                      cols="12"
                      md="3"
                    >
                    <p style="text-align:left;">&nbsp;</p>
                    <v-text-field
                                v-if="settings.fifth_ref_type == 'Value'"
                                v-model="settings.fifth_ref_value"
                                prefix="$"
                                :label="'Amount'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                              <v-text-field
                                v-if="settings.fifth_ref_type == 'Percent'"
                                v-model="settings.fifth_ref_value"
                                prefix="%"
                                :label="'Percent'"
                                v-bind="attrs"
                                v-on="on"
                                color="deep-purple"            
                                
                              >
                              </v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      md="6"
                    ></v-col>

                      <v-col
                      cols="12"
                      md="6"
                    >
                    <v-text-field
                                v-model="settings.business_address"
                                :label="'Meeting Address'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-text-field
                                v-model="settings.video_meeting_link"
                                :label="'Video Meeting Link'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-select
                        v-model="settings.display_inventory_header"
                        :items="users_options"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Display Inventory Header"
                        multiple
                      >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-select
                        v-model="settings.display_purchase_program"
                        :items="['All','None','Custom']"
                        chips
                        label="Display Purchase Program"
                      >
                     
                      </v-select>
                      <v-select
                      v-if="settings.display_purchase_program == 'Custom'"
                        v-model="settings.display_purchase_program_custom"
                        :items="users_options"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Display Purchase Program Users"
                        multiple
                      >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      </v-select>
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    > <v-text-field
                                v-model="settings.purchase_program_link"
                                :label="'Purchase Program Link'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    > <v-text-field
                    prefix="%"
                                v-model="settings.Interest_rate_1"
                                :label="'Credit Score( < 500) Interest Rate'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    > <v-text-field
                    prefix="%"
                                v-model="settings.Interest_rate_2"
                                :label="'Credit Score( 501 - 600) Interest Rate'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                     <v-col
                      cols="12"
                      md="6"
                    > <v-text-field
                    prefix="%"
                                v-model="settings.Interest_rate_3"
                                :label="'Credit Score(601 - 660) Interest Rate'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    > <v-text-field
                    prefix="%"
                                v-model="settings.Interest_rate_4"
                                :label="'Credit Score(661 - 780) Interest Rate'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    > <v-text-field
                    prefix="%"
                                v-model="settings.Interest_rate_5"
                                :label="'Credit Score(781 - 850) Interest Rate'"
                                color="deep-purple"            
                                
                              />
                    </v-col>
                    <!-- <v-col
                      cols="12"
                      md="6"
                    >
                    <html-editor v-model="settings.agreement"></html-editor>
                  
                  </v-col> -->
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#4CAF50"
                        large
                        @click="saveSettings"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        Save
                      </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text  >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Products({{totalCount}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                      <v-btn
                        elevation="0"
                        @click="dialogAssign = true"
                        :ripple="false"
                        small
                        style="float: left;margin-right:15px;"
                        color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                        >Add Product </v-btn
                      >
                      <v-btn
                        @click="$router.push('/admin/preview-products')"
                        small
                        color="blue"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                        >Preview </v-btn
                      >
                         
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            class="table"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            v-sortable-data-table
                            @sorted="saveOrder"
                            :items-per-page="itemsPerPage"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [-1]
                                  }"
  
                        >
                     
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                         <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="productEditOpen(item)"
                            x-small
                            color="blue"
                          >
                            <v-icon class="material-icons-round opacity-10"  size="20" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                       
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                       
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
             <v-col cols="12" md="12">
             <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Coupons({{totalCountCoupons}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;margin-right:15px;"
                                        @click="dialogCoupon = true"
                                    >
                                        Add Coupon 
                                    </v-btn>
                                   
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_coupons"
                            :options.sync="datatable_options_coupons"
                            :items="coupons"
                            loading-text="Loading... Please wait"
                            :page.sync="page_coupons"
                            :items-per-page="itemsPerPage_coupons"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [10, 50, 100,-1]
                                  }"
  
                            class="table"
                        >
                     
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                         <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="promo = item,dialogCoupon=true"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                       
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemCoupon(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCountCoupons"
                                @input="getCoupons"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col> -->
          </v-row>
      </v-tab-item>
      <v-tab-item
                  style="background-color: transparent!important;"
                    :key="1"
                  >
                  
                  <v-row
            align="center"
            justify="center"
            class="mt-10 mb-5"
          >
          <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl " id="basic">
                <v-card-text style="" >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Dashboard Settings</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >

                    <v-select
                      label="Display to"
                        v-model="settings.display_to"
                        :items="['None','All','Super Admin','Custom']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="settings.display_to == 'Custom'"
                        v-model="settings.list_account"
                        :items="companies"
                        chips
                        item-text="name"
                        item-value="id"
                        label="Select Company"
                        multiple
                        solo
                      ></v-select>

                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <label for="my-file">Background</label>
                   <input type="file" accept="image/*" @change="previewImageBG" class="form-control-file" id="my-file-bg">
                   <div class="border p-2 mt-3">
                      <p>Preview Here:</p>
                      <template v-if="previewbg">
                        <v-img contain :src="previewbg" class="img-fluid" />
                      </template>
                    </div>
                    <v-btn
                      dark
                    color="purple"
                    class="float-right ml-2 mt-2"
                    @click="openResize = true"
                  >Resize Logo</v-btn>
                  
                     <v-btn
                      dark
                    color="primary"
                    class="float-right mt-2"
                    @click="uploadLogoBG"
                  >Upload</v-btn>
                    
                    </v-col>
                    </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#4CAF50"
                        large
                        @click="saveSettings"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        Save
                      </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
                    </v-card>
                    </v-col>
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl " id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Custom Links({{totalCountLink}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="editFlag = false,openAdd()"
                                    >
                                      Create New
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_links"
                            :options.sync="datatable_options"
                            :items="links"
                            v-sortable-data-table
                            @sorted="saveOrderLink"
                            loading-text="Loading... Please wait"
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            class="table"
                            :footer-props="{
                                    firstIcon: '',
                                    lastIcon: '',
                                    prevIcon: '',
                                    nextIcon: '',
                                    'page-text': false,
                                    'items-per-page-options': [-1]
                                  }"
                        >
                     
                        <template v-slot:item.is_iframe="{ item }">
                            {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="openEdit(item)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                       
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemLink(item.id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <!-- <div class="text-center pt-2">
                            <v-pagination
                                v-model="pageLink"
                                :length="pageCountLink"
                                @input="getLinks"
                                class="mb-5"
                            ></v-pagination>
                            </div> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
                  
                  </v-tab-item>
         <v-tab-item
                  style="background-color: transparent!important;"
                    :key="2"
                  >
                  <v-row
            align="center"
            justify="center"
            class="mt-10 mb-5"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Letters({{totalCountLetter}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="$router.push('add-letter')"
                                    >
                                        Create New Letter
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_letter"
                            :options.sync="datatable_options_letter"
                            :items="letters"
                            loading-text="Loading... Please wait"
                            :page.sync="page_letter"
                            :items-per-page="itemsPerPage_letter"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.is_iframe="{ item }">
                            {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                        <template v-slot:item.title="{ item }" >
                           <span style="cursor:pointer;color:blue;" @click="$router.push('edit-letter/'+item.id)"> {{item.title}} </span>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="openEdit(item)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                          <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="$router.push('edit-letter/'+item.id)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemLetter(item.id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            circle
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#4CAF50"
                                v-model="page_letter"
                                :length="pageCountLetter"
                                @input="getLetters"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-tab-item>
          <v-tab-item
                  style="background-color: transparent!important;"
                    :key="3"
                  >
                  <v-row
            align="center"
            justify="center"
            class="mt-10 mb-5"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: 0px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Focus Areas({{totalCountFA}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="dialogAddFA = true"
                                    >
                                        Add Focus Area
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_fa"
                            :options.sync="datatable_options_fa"
                            :items="focus_area"
                            loading-text="Loading... Please wait"
                            :page.sync="page_fa"
                            :items-per-page="itemsPerPage_fa"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.is_iframe="{ item }">
                            {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                        <template v-slot:item.title="{ item }" >
                           <span style="cursor:pointer;color:blue;" @click="$router.push('edit-letter/'+item.id)"> {{item.title}} </span>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="dialogAddFA = true"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                          <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="dialogAddFA = true, fa = item"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemFA(item.id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            circle
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#4CAF50"
                                v-model="page_fa"
                                :length="pageCountFA"
                                @input="getFA"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
               <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;" >
                  <v-row>
                      
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <!-- <h1 class="text-center mt-5 mb-5">Company Settings</h1> -->
                    <h5 class="text-h4 font-weight-bold text-typo">Locations({{totalCountL}})</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12" >
                         <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        style="float: left;"
                                        @click="dialogAddL = true"
                                    >
                                        Add Location
                                    </v-btn>
                      </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_l"
                            :options.sync="datatable_options_l"
                            :items="locations"
                            loading-text="Loading... Please wait"
                            :page.sync="page_l"
                            :items-per-page="itemsPerPage_l"
                            hide-default-footer
                            class="table"
                        >
                     
                        <template v-slot:item.is_iframe="{ item }">
                            {{item.is_iframe == 1 ? 'Iframe' :'New Tab'}}
                        </template>
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                        <template v-slot:item.price="{ item }">
                            {{'$'+item.price}}
                        </template>
                        <template v-slot:item.title="{ item }" >
                           <span style="cursor:pointer;color:blue;" @click="$router.push('edit-letter/'+item.id)"> {{item.title}} </span>
                        </template>
                        <template v-slot:item.status="{ item }">
                            {{item.status == 1 ? 'Active' :'Inactive'}}
                        </template>
                        <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="openEdit(item)"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.password="{ item }">
                             <v-btn
                            class="mx-2"
                            fab
                            dark
                            @click="dialogDetail = true,selectedItem = item"
                            x-small
                            color="green"
                          >
                            {{item.total_count}}
                          </v-btn>
                        </template>
                      
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                        <template v-slot:item.document="{ item }">
                          {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                        </template>
                          <template v-slot:item.edit="{ item }">
                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="dialogAddL = true,location = item"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItemLocation(item.id)"
                            x-small
                            color="red"
                          >
                           <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            circle
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination"
                            color="#4CAF50"
                                v-model="page_l"
                                :length="pageCountL"
                                @input="getL"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-tab-item>
       </v-tabs>
      
           <v-dialog
        persistent
      v-model="dialogAssign"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
          Add new Product
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Product
        </v-card-title>
        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                label="Credit"
                v-model="product.credit"
                v-mask="'###########################'"
          ></v-text-field>
          <v-text-field
                v-model="product.price"
                label="Price"
                prefix="$"
                v-mask="'###########################'"
          ></v-text-field>
              <v-select
              label="Type"
            v-model="product.type"
            :items="['Company','Client']"
          ></v-select>
          <v-text-field
                v-model="product.name"
                label="Name"
          ></v-text-field>
          <v-col cols="12">
                     <v-data-table
                          :headers="headers_basic"
                          :items="product.items"
                          sort-by="calories"
                          class="table mb-10"
                        >
                          <template v-slot:top>
                            <v-toolbar
                              flat
                            >
                              <v-toolbar-title>Items</v-toolbar-title>
                              <v-divider
                                class="mx-4"
                                inset
                                vertical
                              ></v-divider>
                              <v-spacer></v-spacer>
                              <v-btn
                                    color="blue"
                                    dark
                                    @click="dialog_basic = true,selected_item = 'plan1'"
                                    class="mb-2"
                                  >
                                    New Item
                                  </v-btn>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              class="material-icons-round mr-2"
                              @click="editItemBasic('plan1',item)"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              small
                              class="material-icons-round"
                              @click="deleteItemBasic('plan1',item)"
                            >
                              delete
                            </v-icon>
                          </template>
                        </v-data-table>
                   </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!product.id"
            color="#4caf50"
            text
            :disabled="loadingAssign"
            @click="addPromo()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editPromo()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAssign = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
                                v-model="dialog_basic"
                                max-width="500px"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                  </v-card-title>

                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          sm="12"
                                          md="12"
                                        >
                                          <v-text-field
                                            v-model="editedItemBasic.item"
                                            label="Enter Item"
                                          ></v-text-field>
                                        </v-col>
                                        
                                      </v-row>
                                    </v-container>
                                  </v-card-text>

                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="closeBasic"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="blue darken-1"
                                      text
                                      @click="saveBasic(selected_item)"
                                    >
                                      Save
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>

    <v-dialog
        persistent
      v-model="dialogAddFA"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!fa.id">
          Add new Focus Area
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Focus Area
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-text-field
                label="Name"
                v-model="fa.name"
          ></v-text-field>
        
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!fa.id"
            color="#4caf50"
            text
            :disabled="loadingAssign"
            @click="addFocusArea()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editFocusArea()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAddFA = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogAddL"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!location.id">
          Add new Location
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Location
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 

          <vuetify-google-autocomplete
                                    id="map"
                                    v-model="location.name"
                                    label="Location"
                                    color="#e91e63"
                                     :rules="rules"
                                    class="font-size-input input-style"
                                    placeholder="Enter a location"
                                    v-on:placechanged="getAddressData"
                                    country="us"
                                ></vuetify-google-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="!location.id"
            color="#4caf50"
            text
            :disabled="loadingAssign"
            @click="addLocation()"
          >
            Submit
          </v-btn><v-btn
          v-else
            color="primary"
            text
            :disabled="loadingAssign"
            @click="editLocation()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogAddL = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        persistent
      v-model="dialogCustomLink"
      width="500"
    >

      <v-card>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
        <v-card-title class="text-h5 grey lighten-2" v-if="editFlag">
          Edit custom menu link 
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Create a custom menu link 
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <span style="color:#5D6167;">Link title</span>
          <v-text-field
                v-model="link.title"
                required
                :rules="nameRules"
                solo
          ></v-text-field>
          <!-- <span style="color:#5D6167;">Link icon</span>
          <v-dialog
            v-model="showDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="700px"
            max-height="850px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="icon"
                :prepend-inner-icon="selectedIcon"
                :label="selectedIcon"
                v-bind="attrs"
                v-on="on"
                color="deep-purple"            
                readonly
                solo  
              >
              </v-text-field>
            </template>
            <v-card>
              <v-app-bar
                color="white"
                scroll-target="#scrolling-techniques-7"
                elevation="0"
              >
                <v-text-field
                  v-model="searchIcon"
                  placeholder="Search icon"
                  prepend-icon="mdi-magnify"
                  type="text"
                  onautocomplete="off"
                  dense
                />
              </v-app-bar>
              <v-sheet
                id="scrolling-techniques-7"
                class="overflow-y-auto"
                max-height="600"
              >
                <v-col cols="12">
                  <v-btn
                    v-for="(item, i) in allIcons"
                    :key="i"
                    @click="saveIcon(item)"
                    color="white"
                    class="mr-2 mb-2 "
                    fab
                    small
                    depressed
                  >
                    <v-icon color="grey darken-3"> mdi-{{ item }} </v-icon>
                  </v-btn>
                </v-col>
                
              </v-sheet>
            </v-card>
          </v-dialog> -->
          <span style="color:#5D6167;">URL</span>
          <v-text-field
                v-model="link.url"
                required
                :rules="nameRules"
                solo
          ></v-text-field>
          <v-switch
          label="Show link on sidebar"
            v-model="link.sidebar"
            inset
          ></v-switch>
          <v-switch
          label="Show to all accounts"
            v-model="link.all_account"
            inset
          ></v-switch>
          <v-select
          v-if="link.all_account == 0"
            v-model="link.list_account"
            :items="companies"
            chips
            item-text="name"
            item-value="id"
            label="Chips"
            multiple
            solo
          ></v-select>
          <v-switch
          label="Show to Dashboard"
            v-model="link.dashboard"
            inset
          ></v-switch>
          <v-switch
          label="Show to Client Profile"
            v-model="link.client"
            inset
          ></v-switch>
          <v-radio-group
            v-model="link.is_iframe"
            row
          >
            <v-radio
              label="Open in iFrame"
              :value="1"
            ></v-radio>
            <v-radio
              label="Open in a New Tab"
              :value="0"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!editFlag"
            :disabled="loadingCustomLink"
            @click="addLink()"
          >
            Submit
          </v-btn>
          <v-btn
            color="primary"
            text
            v-if="editFlag"
            :disabled="loadingCustomLink"
            @click="editLink()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingCustomLink"
            @click="dialogCustomLink = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteBasic" max-width="500px">
                                <v-card>
                                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDeleteBasic">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirmBasic(selected_item)">OK</v-btn>
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
    <v-dialog
        persistent
      v-model="dialogCoupon"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" v-if="!promo.id">
          Add new Coupon code
        </v-card-title>
        <v-card-title class="text-h5 grey lighten-2" v-else>
          Edit Coupon code
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-text-field
          label="Name"
                v-model="promo.name"
                :disabled="promo.id ? true : false"
          ></v-text-field>
                 <v-select
                            label="Type"
                            placeholder="Select type"
                            :disabled="loadingAssign"
                            v-model="promo.type"
                            :items="['Percentage','Fixed']"
                            ></v-select>
          <v-text-field
          label="Discount"
          v-if="promo.type == 'Fixed'"
                v-model="promo.value"
                prefix="$"
                v-mask="'###########################'"
          ></v-text-field>
              <v-text-field
              label="Discount"
          v-if="promo.type == 'Percentage'"
                v-model="promo.value"
                suffix="%"
                v-mask="'###'"
          ></v-text-field>
                 <v-select
                 label="Usage Limit"
                            placeholder="Select Limit"
                            v-model="promo.count"
                            :items="['Unlimited','1','2','5','10','15']"
                            ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!promo.id"
            :disabled="loadingAssign"
            @click="addCoupon()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            v-else
            :disabled="loadingAssign"
            @click="editCoupon()"
          >
            Submit
          </v-btn>
           <v-btn
            color="primary"
            text
            :disabled="loadingAssign"
            @click="dialogCoupon = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
    </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
import Sortable from 'sortablejs';
export default {
components: {
      VueTelInput,HtmlEditor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        id: '',
        name:'',
        editedItemBasic: {
          item: '',
        },
        payment_gateway_options: [],
        phone:'',
        password : '',
        users : [],
        searchTerm:'',
        nameRules: [
        v => !!v || 'Field is required'
      ],
        valid: true,
        page:1,
        promo: {
          name : '',
          type : 'Percentage',
          value : '',
          count : 1,
        },
        itemsPerPage:10,
        confpassword : '',
        users_options: [],
        users_options_old: [],
        dialogCoupon : false,
        role : '',
        headers_letter : [
           { text: 'Title', value: 'title',
            sortable: true },
          { text: 'Category', value: 'category',
            sortable: true },
          { text: 'Staus', value: 'status',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        headers_fa : [
           { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
         headers_l : [
           { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        datatable_options_letter: {},
        datatable_options_fa: {},
        datatable_options_l: {},
        dialogAddFA : false,
        dialogAddL : false,
        headers_coupons : [  { text: 'Name', value: 'name',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
          { text: 'Value', value: 'value',
            sortable: true },
             { text: 'Usage Limit', value: 'count',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: true,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },],
        datatable_options_coupons : {},
        coupons : [],
        page_coupons : 1,
        page_fa : 1,
        page_l : 1,
        itemsPerPage_coupons :10,
        page_letter : 1,
        itemsPerPage_letter: 10,
        dialogAssign: false,
        pageCount: 1,
        fa : {
          name : '',
          id : ''
        },
        location : {
          name : '',
          id : ''
        },
        pageCountLetter : 1,
        pageCountCoupons : 1,
        pageCountFA : 1,
        pageCountL : 1,
        pageLink:1,
        pageCountLink: 1,
        totalCount: 0,
        totalCountLink: 0,
        companyname : '',
        previewbg:'',
        dialogDeleteBasic :  false,
        focus_area : [],
        headers_basic: [
          { text: 'Item', value: 'item' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        dialog_basic: false,
        locations : [],
        sector : '',
        no_of_employee : '',
        showPassword : false,
        loadingAssign: false,
        limit: 10,
        limit_letter: 10,
        totalCountLetter : 0,
 
        totalCountFA : 0,
        totalCountL : 0,
        letters : [],
        dialogCustomLink: false,
        loadingCustomLink: false,
        phoneFlag:0,
        emailFlag:0,
        link: {
          title:'',
          icon: 'mdi-web',
          url: '',
          is_iframe: 1,
          sidebar:1,
          all_account: 1,
          list_account:[],
          dashboard : 0,
          client: 0
        },
        product: {
          credit: 0,
          price: 0,
          type :'Company',
          items : [],
          name : ''
        },
        country :'',
        datatable_options:{},
        otp_email:'',
        otp_phone:'',
        links: [],
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        editFlag: false,
        companies: [],
        user_email:'',
        headers_jobs: [
          { text: 'Price', value: 'price',
            sortable: true },
          { text: 'Credit', value: 'credit',
            sortable: true },
          { text: 'Type', value: 'type',
            sortable: true },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: false },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        headers_links: [
          { text: 'Title', value: 'title',
            sortable: false },
          { text: 'URL', value: 'url',
            sortable: false },
          { text: 'Open Mode', value: 'is_iframe',
            sortable: false },
          { text: 'Created at', value: 'created_at',
            sortable: false,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: false,width: '200' },
            { text: 'Edit', value: 'edit',
            sortable: false,width: '200' },
          { text: 'Delete', value: 'id',
            sortable: false,width: '50' },
        ],
        status:0,
        settings :{
            payment_gateway: '',
            test_mode : 1,
            grant_count: 10,
            list_account : [],
            backgroung_logo: '',
            display_to : 'All',
            pricing :{
                user_credit : "",
            }

        },
        tab: null,
        showDialog: false,
      icon: '',
      searchIcon: '',
      selectedIcon: 'mdi-check-outline',
      allIcons: [
        "account",
        "account-alert",
        "account-box",
        "account-box-outline",
        "account-check",
        "account-circle",
        "account-key",
        "tooltip-account",
        "account-minus",
        "web",
        "account-multiple",
        "account-multiple-outline",
        "account-multiple-plus",
        "account-network",
        "account-outline",
        "account-plus",
        "account-remove",
        "account-search",
        "account-star",
        "account-switch",
        "airballoon",
        "airplane",
        "airplane-off",
        "alarm",
        "alarm-check",
        "alarm-multiple",
        "alarm-off",
        "alarm-plus",
        "album",
        "alert",
        "alert-box",
        "alert-circle",
        "alert-octagon",
        "alpha",
        "alphabetical",
        "amazon",
        "google-cloud",
        "ambulance",
        "android",
        "android-debug-bridge",
        "android-studio",
        "apple",
        "apple-finder",
        "apple-ios",
        "apple-safari",
        "apps",
        "archive",
        "arrange-bring-forward",
        "arrange-bring-to-front",
        "arrange-send-backward",
        "arrange-send-to-back",
        "arrow-all",
        "arrow-bottom-left",
        "arrow-bottom-right",
        "arrow-collapse",
        "arrow-down",
        "arrow-down-bold",
        "arrow-down-bold-circle",
        "arrow-down-bold-circle-outline",
        "arrow-down-bold-hexagon-outline",
        "arrow-expand",
        "arrow-left",
        "arrow-left-bold",
        "arrow-left-bold-circle",
        "arrow-left-bold-circle-outline",
        "arrow-left-bold-hexagon-outline",
        "arrow-right",
        "arrow-right-bold",
        "arrow-right-bold-circle",
        "arrow-right-bold-circle-outline",
        "arrow-right-bold-hexagon-outline",
        "arrow-top-left",
        "arrow-top-right",
        "arrow-up",
        "arrow-up-bold",
        "arrow-up-bold-circle",
        "arrow-up-bold-circle-outline",
        "arrow-up-bold-hexagon-outline",
        "at",
        "attachment",
        "auto-fix",
        "auto-upload",
        "baby",
        "backburger",
        "backup-restore",
        "bank",
        "barcode",
        "barley",
        "barrel",
        "basket",
        "basket-fill",
        "basket-unfill",
        "battery",
        "battery-10",
        "battery-20",
        "battery-30",
        "battery-40",
        "battery-50",
        "battery-60",
        "battery-70",
        "battery-80",
        "battery-90",
        "battery-alert",
        "battery-charging-100",
        "battery-charging-20",
        "battery-charging-30",
        "battery-charging-40",
        "battery-charging-60",
        "battery-charging-80",
        "battery-charging-90",
        "battery-minus",
        "battery-negative",
        "battery-outline",
        "battery-plus",
        "battery-positive",
        "battery-unknown",
        "beach",
        "beaker",
        "beaker-outline",
        "beer",
        "bell",
        "bell-off",
        "bell-outline",
        "bell-ring",
        "bell-ring-outline",
        "bell-sleep",
        "beta",
        "bike",
        "binoculars",
        "bio",
        "biohazard",
        "bitbucket",
        "black-mesa",
        "blinds",
        "block-helper",
        "blogger",
        "bluetooth",
        "bluetooth-audio",
        "bluetooth-connect",
        "bluetooth-settings",
        "bluetooth-transfer",
        "blur",
        "blur-linear",
        "blur-off",
        "blur-radial",
        "bone",
        "book",
        "book-multiple",
        "book-open",
        "book-variant",
        "bookmark",
        "bookmark-check",
        "bookmark-music",
        "bookmark-outline",
        "bookmark-plus",
        "bookmark-remove",
        "border-all",
        "border-bottom",
        "border-color",
        "border-horizontal",
        "border-inside",
        "border-left",
        "border-none",
        "border-outside",
        "border-right",
        "border-top",
        "border-vertical",
        "bowling",
        "box",
        "briefcase",
        "briefcase-check",
        "briefcase-download",
        "briefcase-upload",
        "brightness-1",
        "brightness-2",
        "brightness-3",
        "brightness-4",
        "brightness-5",
        "brightness-6",
        "brightness-7",
        "brightness-auto",
        "broom",
        "brush",
        "bug",
        "bulletin-board",
        "bullhorn",
        "bus",
        "cake",
        "cake-variant",
        "calculator",
        "calendar",
        "calendar-blank",
        "calendar-check",
        "calendar-clock",
        "calendar-multiple",
        "calendar-multiple-check",
        "calendar-plus",
        "calendar-remove",
        "calendar-text",
        "calendar-today",
        "camcorder",
        "camcorder-off",
        "camera",
        "camera-front",
        "camera-front-variant",
        "camera-iris",
        "camera-party-mode",
        "camera-rear",
        "camera-rear-variant",
        "camera-switch",
        "camera-timer",
        "candycane",
        "car",
        "car-wash",
        "carrot",
        "cart",
        "cart-outline",
        "cash",
        "cash-100",
        "cash-multiple",
        "cash-usd",
        "cast",
        "cast-connected",
        "castle",
        "cat",
        "cellphone",
        "cellphone-android",
        "cellphone-dock",
        "cellphone-iphone",
        "cellphone-link",
        "cellphone-link-off",
        "cellphone-settings",
        "chair-school",
        "chart-arc",
        "chart-areaspline",
        "chart-bar",
        "chart-histogram",
        "chart-line",
        "chart-pie",
        "check",
        "check-all",
        "checkbox-blank",
        "checkbox-blank-circle",
        "checkbox-blank-circle-outline",
        "checkbox-blank-outline",
        "checkbox-marked",
        "checkbox-marked-circle",
        "checkbox-marked-circle-outline",
        "checkbox-marked-outline",
        "checkbox-multiple-blank",
        "checkbox-multiple-blank-outline",
        "checkbox-multiple-marked",
        "checkbox-multiple-marked-outline",
        "checkerboard",
        "chevron-double-down",
        "chevron-double-left",
        "chevron-double-right",
        "chevron-double-up",
        "chevron-down",
        "chevron-left",
        "chevron-right",
        "chevron-up",
        "church",
        "city",
        "clipboard",
        "clipboard-account",
        "clipboard-alert",
        "clipboard-arrow-down",
        "clipboard-arrow-left",
        "clipboard-check",
        "clipboard-outline",
        "clipboard-text",
        "clippy",
        "clock",
        "clock-fast",
        "close",
        "close-box",
        "close-box-outline",
        "close-circle",
        "close-circle-outline",
        "close-network",
        "closed-caption",
        "cloud",
        "apple-icloud",
        "cloud-check",
        "cloud-circle",
        "cloud-download",
        "cloud-outline",
        "cloud-off-outline",
        "cloud-upload",
        "cloud-refresh",
        "cloud-lock",
        "cloud-lock-outline",
        "cloud-question",
        "cloud-tags",
        "cloud-print",
        "cloud-print-outline",
        "cloud-search",
        "cloud-search-outline",
        "code-array",
        "code-braces",
        "code-equal",
        "code-greater-than",
        "code-less-than",
        "code-less-than-or-equal",
        "code-not-equal",
        "code-not-equal-variant",
        "code-string",
        "code-tags",
        "codepen",
        "coffee",
        "coffee-to-go",
        "color-helper",
        "comment",
        "comment-account",
        "comment-account-outline",
        "comment-alert",
        "comment-alert-outline",
        "comment-check",
        "comment-check-outline",
        "comment-multiple-outline",
        "comment-outline",
        "comment-plus-outline",
        "comment-processing",
        "comment-processing-outline",
        "comment-remove-outline",
        "comment-text",
        "comment-text-outline",
        "compare",
        "compass",
        "compass-outline",
        "console",
        "content-copy",
        "content-cut",
        "content-duplicate",
        "content-paste",
        "content-save",
        "content-save-all",
        "contrast",
        "contrast-box",
        "contrast-circle",
        "cow",
        "credit-card",
        "credit-card-multiple",
        "crop",
        "crop-free",
        "crop-landscape",
        "crop-portrait",
        "crop-square",
        "crosshairs",
        "crosshairs-gps",
        "crown",
        "cube",
        "cube-outline",
        "cube-unfolded",
        "cup",
        "cup-water",
        "currency-btc",
        "currency-eur",
        "currency-gbp",
        "currency-inr",
        "currency-rub",
        "currency-try",
        "currency-usd",
        "cursor-default",
        "cursor-default-outline",
        "cursor-move",
        "cursor-pointer",
        "database",
        "database-minus",
        "database-outline",
        "database-plus",
        "debug-step-into",
        "debug-step-out",
        "debug-step-over",
        "decimal-decrease",
        "decimal-increase",
        "delete",
        "delete-variant",
        "deskphone",
        "desktop-mac",
        "desktop-tower",
        "details",
        "deviantart",
        "diamond",
        "dice-1",
        "dice-2",
        "dice-3",
        "dice-4",
        "dice-5",
        "dice-6",
        "directions",
        "disqus",
        "division",
        "division-box",
        "dns",
        "domain",
        "dots-horizontal",
        "dots-vertical",
        "download",
        "drag",
        "drag-horizontal",
        "drag-vertical",
        "drawing",
        "drawing-box",
        "drone",
        "dropbox",
        "drupal",
        "duck",
        "dumbbell",
        "earth",
        "earth-off",
        "eject",
        "elevation-decline",
        "elevation-rise",
        "elevator",
        "email",
        "email-open",
        "email-outline",
        "emoticon",
        "emoticon-cool",
        "emoticon-devil",
        "emoticon-happy",
        "emoticon-neutral",
        "emoticon-poop",
        "emoticon-sad",
        "emoticon-tongue",
        "engine",
        "engine-outline",
        "equal",
        "equal-box",
        "eraser",
        "escalator",
        "evernote",
        "exclamation",
        "exit-to-app",
        "export",
        "eye",
        "eye-off",
        "eyedropper",
        "eyedropper-variant",
        "facebook",
        "facebook-messenger",
        "factory",
        "fan",
        "fast-forward",
        "ferry",
        "file",
        "file-cloud",
        "file-delimited",
        "file-document",
        "file-excel-box",
        "file-find",
        "file-image",
        "file-multiple",
        "file-music",
        "file-outline",
        "file-pdf",
        "file-pdf-box",
        "file-powerpoint",
        "file-powerpoint-box",
        "file-presentation-box",
        "file-video",
        "file-word",
        "file-word-box",
        "film",
        "filmstrip",
        "filmstrip-off",
        "filter",
        "filter-outline",
        "filter-remove-outline",
        "filter-variant",
        "fire",
        "firefox",
        "fish",
        "flag",
        "flag-checkered",
        "flag-outline",
        "flag-triangle",
        "flag-variant",
        "flash",
        "flash-auto",
        "flash-off",
        "flashlight",
        "flashlight-off",
        "flip-to-back",
        "flip-to-front",
        "floppy",
        "flower",
        "folder",
        "folder-account",
        "folder-download",
        "folder-google-drive",
        "folder-image",
        "folder-lock",
        "folder-lock-open",
        "folder-move",
        "folder-multiple",
        "folder-multiple-image",
        "folder-multiple-outline",
        "folder-outline",
        "folder-plus",
        "folder-remove",
        "folder-upload",
        "food",
        "food-apple",
        "food-variant",
        "football",
        "football-helmet",
        "format-align-center",
        "format-align-justify",
        "format-align-left",
        "format-align-right",
        "format-bold",
        "format-clear",
        "format-color-fill",
        "format-float-center",
        "format-float-left",
        "format-float-none",
        "format-float-right",
        "format-header-1",
        "format-header-2",
        "format-header-3",
        "format-header-4",
        "format-header-5",
        "format-header-6",
        "format-header-decrease",
        "format-header-equal",
        "format-header-increase",
        "format-header-pound",
        "format-indent-decrease",
        "format-indent-increase",
        "format-italic",
        "format-line-spacing",
        "format-list-bulleted",

        "format-paint",
        "format-paragraph",

        "format-size",
        "format-strikethrough",
        "format-subscript",
        "format-superscript",
        "format-text",
        "format-textdirection-l-to-r",
        "format-textdirection-r-to-l",
        "format-underline",
        "format-wrap-inline",
        "format-wrap-square",
        "format-wrap-tight",
        "format-wrap-top-bottom",
        "forum",
        "forward",
        "fridge",
        "fullscreen",
        "fullscreen-exit",
        "function",
        "gamepad",
        "gamepad-variant",
        "gas-station",
        "gavel",
        "gender-female",
        "gender-male",
        "gender-male-female",
        "gender-transgender",
        "gift",
        "git",
        "github",
        "glass-flute",
        "glass-mug",
        "glass-stange",
        "glass-tulip",
        "glasses",
        "gmail",
        "google",
        "google-chrome",
        "google-circles",
        "google-circles-communities",
        "google-circles-extended",
        "google-circles-group",
        "google-controller",
        "google-controller-off",
        "google-drive",
        "google-earth",
        "google-glass",
        "google-maps",

        "google-play",
        "google-plus",
        "google-hangouts",
        "grid",
        "grid-off",
        "group",

        "guitar-pick",
        "guitar-pick-outline",
        "hand-pointing-right",
        "hanger",

        "harddisk",
        "headphones",
        "headphones-box",
        "headphones-settings"
      ],


      }
    },
    directives: {
    sortableDataTable: {
      bind (el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
    methods: {
      saveOrder (event) {
      const movedItem = this.users.splice(event.oldIndex, 1)[0];
      this.users.splice(event.newIndex, 0, movedItem);
       axios({
          method: "POST",
           
          url: "/api/save-product-order",
          data : this.users,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            console.log('product order saved');

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    saveOrderLink (event) {
      const movedItem = this.links.splice(event.oldIndex, 1)[0];
      this.links.splice(event.newIndex, 0, movedItem);
       axios({
          method: "POST",
           
          url: "/api/save-link-order",
          data : this.links,
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            console.log('product order saved');

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    openAdd(){
      this.link = {
          title:'',
          icon: 'mdi-web',
          url: '',
          is_iframe: 1,
          sidebar:1,
          all_account: 1,
          list_account:[],
        };
      this.dialogCustomLink = true;
    },
    openEdit(item){
      
      this.editFlag = true;
      this.link = item;
      this.link.list_account = Array.isArray(this.link.list_account) ? this.link.list_account : JSON.parse(this.link.list_account)
      console.log(this.link);
      this.selectedIcon = item.icon;
      this.dialogCustomLink = true;
    },
    getSettings() {
    let flag = 1;
    let url =process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
           
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.id = data.id;
              this.settings = data.settings;
              this.previewbg = url+data.settings.backgroung_logo
            }


         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     productEditOpen(item){
      this.product = item
      this.product.items = this.product.items ? Array.isArray(this.product.items) ? this.product.items :JSON.parse(this.product.items) : []
      this.dialogAssign=true
     },
     previewImageBG: function(event) {
      var input = event.target;
      console.log(input);
      if (input.files) {
        this.settings.backgroung_logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewbg = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    searchFruits(e) {
      if (!this.searchTerm) {
        this.users_options = this.users_options_old;
      }
      this.users_options = this.users_options_old.filter((fruit) => {
        return fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    },
     getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-client", {headers: headers})
            .then(response => {
                this.companies = this.users_options_old = this.users_options = response.data.data;
            })
        },
    deleteItem(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-promo/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getProducts();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLocation(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-location/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getL();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemConfirmBasic (plan) {
        this.product.items.splice(this.editedIndexBasic, 1)
        this.closeDeleteBasic()
      },
        deleteItemFA(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-focus-area/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getFA();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLetter(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-letter/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLetters();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemLink(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-link/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getLinks();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        deleteItemBasic (flag,item) {
        this.editedIndexBasic = this.product.items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialogDeleteBasic = true
      },
        closeBasic () {
        this.dialog_basic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item : ''})
          this.editedIndexBasic = -1
        })
      },
        getAddressData: function (addressData, placeResultData, id) {
            this.location.name = addressData.name;
        },
        editItemBasic (flag,item) {
        this.editedIndexBasic = this.product.items.indexOf(item)
        this.editedItemBasic = Object.assign({}, item)
        this.dialog_basic = true
      },
        saveBasic (item) {
        if (this.editedIndexBasic > -1) {
          Object.assign(this.product.items[this.editedIndexBasic], this.editedItemBasic)
        } else {
          this.product.items.push(this.editedItemBasic)
        }
        this.closeBasic()
      },
        deleteItemCoupon(id){
       
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .get("/api/delete-coupon/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getCoupons();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
    getPayments(){
            this.loading = true;
           
            axios({
          method: "GET",
           
          url: "/api/get-all-payment-gateway",
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
            this.payment_gateway_options = response.data.data
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
    addPromo(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-promo",
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Product added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addFocusArea(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-focus-area",
          data: this.fa
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Focus Area added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddFA = false;
                      this.loadingAssign = false;
                      this.getFA();
                      this.fa.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addLocation(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-location",
          data: this.location
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Location added successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddL = false;
                      this.loadingAssign = false;
                      this.getL();
                      this.location.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addCoupon(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-coupon-codes",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Coupon added successfully', {
                      position: 'top-right'
                      });
                      this.dialogCoupon = false;
                      this.loadingAssign = false;
                      this.getCoupons();
                      this.promo = {
                            name : '',
                            type : 'Percentage',
                            value : '',
                            count : 1
                          };
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editCoupon(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-coupon/"+this.promo.id,
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Coupon edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogCoupon = false;
                      this.loadingAssign = false;
                      this.getCoupons();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editPromo(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-promo/"+this.product.id,
          data: this.product
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Product edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAssign = false;
                      this.loadingAssign = false;
                      this.getProducts();
                      this.product.price = '';
                      this.product.credit = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      editFocusArea(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-focus-area/"+this.fa.id,
          data: this.fa
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Focus Area edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddFA = false;
                      this.loadingAssign = false;
                      this.getFA();
                      this.fa.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      uploadLogoBG(){
      const data = new FormData();
      let url =process.env.VUE_APP_WASBI_URL
        data.append('file_logo', this.settings.backgroung_logo);
        axios({
          method: "POST",
          url: "/api/upload-logo-background-admin",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              console.log();
              this.settings.backgroung_logo = response.data.data
              this.saveSettings()
              localStorage.setItem('admin_bg',url+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
      editLocation(){
        this.loadingAssign = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-location/"+this.location.id,
          data: this.location
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Location edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogAddL = false;
                      this.loadingAssign = false;
                      this.getL();
                      this.location.name = '';
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      addLink(){
        if(this.$refs.form.validate()){
        this.loadingCustomLink = true;
        let req = this.link;
        req.icon = this.selectedIcon;
        req.list_account = JSON.stringify(req.list_account)
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-cusom-link",
          data: req
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Custom Link added successfully', {
                      position: 'top-right'
                      });
                      this.dialogCustomLink = false;
                      this.loadingCustomLink = false;
                      this.getLinks();
                      this.link.title = '';
                      this.link.url= '';
                      this.link.list_account= [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
              this.loadingCustomLink = false;
              })
        }
          
            
      },
      editLink(){
        if(this.$refs.form.validate()){
        this.loadingCustomLink = true;
        let req = this.link;
        req.icon = this.selectedIcon;
        req.list_account = JSON.stringify(req.list_account)
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-custom-link",
          data: req
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Custom Link edited successfully', {
                      position: 'top-right'
                      });
                      this.dialogCustomLink = false;
                      this.loadingCustomLink = false;
                      this.getLinks();
                      this.link.title = '';
                      this.link.url= '';
                      this.link.list_account= [];
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
              this.loadingCustomLink = false;
              })
        }
          
            
      },
    getProducts(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-promo-codes",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                console.log( this.users);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getFA(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_fa.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-focus-area",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.focus_area = data.data;
                this.pageCountFA = data.total_page
                this.totalCountFA = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getL(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_l.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : ''
            }
            axios({
          method: "POST",
           
          url: "/api/get-location",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.locations = data.data;
                this.pageCountL = data.total_page
                this.totalCountL = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getCoupons(){
      this.loading = true;
            this.offset = (this.page - 1) * this.datatable_options_coupons.itemsPerPage
            this.selected_project = name;
            let req = {
              limit : this.datatable_options_coupons.itemsPerPage,
              offset : this.offset,
              sort : this.datatable_options_coupons.sortBy,
              sortDesc : this.datatable_options_coupons.sortDesc && this.datatable_options_coupons.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-coupon-codes",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.coupons = data.data;
                this.pageCountCoupons = data.total_page
                this.totalCountCoupons = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLinks(){
      this.loading = true;
            this.offset = (this.pageLink - 1) * 10
            this.selected_project = name;
            let req = {
              limit : -1,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-custom-links",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.links = data.data;
                this.pageCountLink = data.total_page
                this.totalCountLink = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    getLetters(){
      this.loading = true;
            var offset = (this.page_letter - 1) * 10
            let req = {
              limit : this.limit_letter,
              offset : offset,
              sort : this.datatable_options_letter.sortBy,
              sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search_letter
            }
            axios({
          method: "POST",
           
          url: "/api/get-all-letter",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.letters = data.data;
                this.pageCountLetter = data.total_page
                this.totalCountLetter = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
    },
    closeDeleteBasic () {
        this.dialogDeleteBasic = false
        this.$nextTick(() => {
          this.editedItemBasic = Object.assign({}, {item:''})
          this.editedIndexBasic = -1
        })
      },
    saveSettings: function(e) {

        axios({
          method: "POST",
           
          url: "/api/update-company-settings",
          data: {
            id: this.id,
            settings: this.settings
          }
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings saved', {
                    position: 'top-right'
                  })
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
    },
    saveIcon: function(icon) {
      this.icon = icon;
      this.selectedIcon = "mdi-" + icon;
      this.showDialog = false;
    }

 },
 
watch: {
  dialogDeleteBasic (val) {
        val || this.closeDeleteBasic()
      },
    searchIcon: function(oldSearchIcon, newSearchIcon) {
    let filteredIcons = [];
    if (this.searchIcon.length === 0) {
      filteredIcons = this.allIcons;
    } else {
      filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
    }
    this.allIcons = filteredIcons;
  },
  datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getProducts()
                },
                deep: true,
            },
  datatable_options_coupons : {
    handler () {
                this.getCoupons()
                },
                deep: true,
  },
  datatable_options_fa : {
    handler () {
                this.getFA()
                },
                deep: true,
  },
  datatable_options_l : {
    handler () {
                this.getL()
                },
                deep: true,
  }
},
created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      console.log('created called.');
      this.getSettings();
      // this.getProducts();
      //this.getLinks()
      this.getAllCompanies();
      //this.getLetters();
      this.getPayments();
    }
      
    },

}
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

</style>