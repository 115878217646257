<template>
    <div>
      <v-card
    :loading="loading"
    class="mx-auto mb-3"
    max-width="374"
    style="border-radius: 0px;"
    elevation="4"
    v-for="user in users" v-bind:key="user.id" v-if="user.id"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
  <v-carousel v-model="model" hide-delimiters height="250">
    <v-carousel-item
      v-for="(color, i) in user.images"
      :key="i"
    >
     <v-img
      height="250"
      :src="color.image"
    ></v-img>
       <v-overlay absolute class="inventory-overlay" style="opacity: 0.4!important;">
       
        <v-card color="transparent" class="d-flex justify-end align-end" style="height:100%;width:100%;text-align:right">
        <v-card-text>{{i+1}}/{{user.images.length}}</v-card-text>
      </v-card>
      </v-overlay >
    </v-carousel-item>
  </v-carousel>
    <v-card-title class="text-h4 font-weight-bold">{{user.year}} {{user.make}} {{user.model}}</v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0 mb-1 mt-1"
      >
      <!-- <v-btn
                    elevation="0"
                    small
                    v-if="user.category_cow"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    color="#bce2be"
                    >Car Of The Week</v-btn
                  >
                  <v-btn
                    elevation="0"
                    small
                     v-if="user.category_cl"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    color="#bce2be"
                    >{{  'Clearance' }}</v-btn
                  >
                  <v-btn
                    elevation="0"
                    small
                    v-if="!user.category_cl && !user.category_cow"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    color="#bce2be"
                    >{{  'New Stock' }}</v-btn
                  > -->
                  <div data-v-0732192f="" class="vehicle-identifiers" style="width: 100%;">
                  <div data-v-0732192f="" class="vehicle-identifiers__vin" style="float: left;"><span data-v-0732192f="" class="vehicle-identifiers__label">VIN: </span><span data-v-0732192f="" class="vehicle-identifiers__value">{{user.vin}}</span></div><div data-v-0732192f="" class="vehicle-identifiers__stock" style="float: right;"><span data-v-0732192f="" class="vehicle-identifiers__label">Stock: </span><span data-v-0732192f="" class="vehicle-identifiers__value">{{user.stock_number}}</span></div></div>
      </v-row>
      <v-row
        class="mx-0 mb-1 mt-1"
      >
      <v-divider class=" mt-2"></v-divider>
      
      </v-row>
      <v-row
        class="mx-0 mb-1 mt-2"
      >
      <div data-v-0732192f="" class="vehicle-overview__specs" style="width: 100%;"><div data-v-0732192f="" class="vehicle-overview__mileage" style="float: left;"><div data-v-0732192f="" class="vehicle-mileage" style="font-size: 1.3em;">86,801 mi</div></div><div data-v-0732192f="" class="vehicle-overview__colors" style="float: right;"><div data-v-0732192f="" class="vehicle-colors"><div data-v-0732192f="" class="vehicle-colors__ext"><span data-v-0732192f="" class="vehicle-colors__label">Ext. {{user.exterior_color}}</span> <span data-v-0732192f="" class="vehicle-colors__label ml-5">Int. {{user.interior_color}}</span></div></div></div></div>
      </v-row>
      <!-- <div class="my-4 text-subtitle-1" >
        <span class="text-h4 font-weight-bold mt-2" style="font-family: LatoGoogle,sans-serif;font-weight: 700;color: black;">{{ currencyFormat(user.amount) }}</span> <span class="float-right mt-1">{{numberFormat(user.odometer)}} mi</span>
      </div> -->
    </v-card-text>

    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row
        align="center"
        class="mx-0 mb-1 mt-1"
      >
        <span class="text-h2 font-weight-bold mt-2 text-center" style="font-family: LatoGoogle,sans-serif!important;font-weight: 800!important;color: #3A9705;width: 100%;">{{ currencyFormat(user.amount) }}</span>
        <div style="width: 100%;text-align: center">
        <v-btn
                    elevation="0"
                    small
                    v-if="user.category_cow"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    >Car Of The Week</v-btn
                  >
                  <v-btn
                    elevation="0"
                    small
                     v-if="user.category_cl"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    >{{  'Clearance' }}</v-btn
                  >
                  <v-btn
                    elevation="0"
                    small
                    v-if="!user.category_cl && !user.category_cow"
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      badge-font-size
                      text-success
                      mr-2
                    "
                    >{{  'New Stock' }}</v-btn
                  >
                  </div>
      </v-row>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
       <v-card-actions >
       <v-btn
              class=""
                block
                style="text-transform:none;"
                color="#1B263B"
                :dark="true"  
                v-if="checkPermissionPurchaseProgram()"
                large             
                @click="gotoLinks(company_settings.purchase_program_link)"
              >AutoSyncX™️ Purchase Support Program
              </v-btn>
             
    </v-card-actions>
    <v-card-actions >
      <v-btn
                color="#1B263B"
                :dark="true"
                block
                outlined
                large
                style="margin-top:-5px;"
                @click="viewDetail(user.id)"
              > View Detail
              </v-btn>
             
    </v-card-actions>
    <v-card-actions >
       <v-btn
              class=""
                block
                style="margin-top:-5px;"
                color="#1B263B"
                :dark="true"  
                large             
                @click="gotoLinks('https://roundtables.mycarsonline.com/get-pre-qualified')"
              >Get Pre Approved!
              </v-btn>
             
    </v-card-actions>
    <!-- <v-card-actions    >
     
    </v-card-actions> -->
  
  </v-card>
  <v-dialog
                      v-model="overlay_inventory"
                      width="500"
                    >
                    
                    <div style="padding: 10!important;" >
                    <Query class="mt-5" :color="'black'" :id="selected_item" :ref_id="company.user_id" />
                    </div>
                    </v-dialog>
  </div>
</template>

<script>
import Query from "@/components/Forms/Query.vue";
export default {
props: ["users","company","company_settings"],
components: {Query},
  data: () => ({
    query: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
       
    },
    overlay_inventory : false,
    selected_item: '',
    base_url_img : process.env.VUE_APP_WASBI_URL,

  }),
  methods: {
    getParentDomain() {
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.');
        
        // Assuming the domain is of the form subdomain.example.com
        if (domainParts.length >= 2) {
            const parentDomain = domainParts.slice(-2).join('.');
            return parentDomain;
        } else {
            return hostname; // For cases like localhost or single part domains
        }
    },
    checkPermissionPurchaseProgram(flag) {
        let status = 0;
        if(this.company_settings.display_purchase_program == 'None'){
          return false
        }
        else if(this.company_settings.display_purchase_program == 'All'){
          return true
        }else{
          if(this.company_settings.display_purchase_program_custom.length != 0){
          this.company_settings.display_purchase_program_custom.forEach(val => {
            if(val == this.company.user_id){
              status = 1;
            }
            
          });
        }
          return status
        }
     },
    viewDetail(id){
      if(localStorage.getItem('view-inventory-flag') && localStorage.getItem('view-inventory-flag') == 1){
        this.$router.push('/view-inventory-details/'+id)
      }else{
        this.overlay_inventory = true
        this.selected_item = id
      }
    },
    gotoLinks(link){
      if(!link)
      link = '#'
      const newTab = window.open('', '_blank');
      newTab.location.href = link;
    },
    currencyFormat(value){
      const roundedValue = Math.round(value).toFixed(2);
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0, // Ensures no decimals are shown
          maximumFractionDigits: 0  // Ensures no decimals are shown
        }).format(roundedValue);
      },
      numberFormat(value){
        return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(
          value,
  )
      },
    gotoCard(vcard){
      let id = vcard.domain
      let route = '';
      let url = 'http://'+id+'.'+this.getParentDomain();
        window.open(url, '_blank');

    },
  }
}
</script>

<style>
.v-window__next .v-icon{
  font-size: 25px!important;
}
.v-window__prev .v-icon{
  font-size: 25px!important;
}
.inventory-overlay .v-overlay__content{
  height:100%;width:100%;
}
</style>