<template>
  <v-app class="bg-white">
    <v-overlay :value="loader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-main class="auth-pages" v-if="company" >

    
          <div class="footer" id="myHeader" :style="getStyle('footer')">
            <div class="elementor-container elementor-column-gap-default" style="width: 100%;">
              <v-row style="margin:0px!important;">
      <v-col style="padding: 1px;">
					<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-a828a20" data-id="a828a20" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-88f37c7 elementor-view-default elementor-widget elementor-widget-icon" data-id="88f37c7" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="phone">
			<i aria-hidden="true" class="fa fa-id-card" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-f383dda elementor-widget elementor-widget-heading" data-id="f383dda" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default" ><a :href="phone" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Card</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col style="padding: 1px;">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1eed04c" data-id="1eed04c" data-element_type="column" id="share-my-card" @click="overlay = true">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-61dd731 elementor-view-default elementor-widget elementor-widget-icon" data-id="61dd731" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" href="#">
			<i aria-hidden="true" class="fa fa-qrcode" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-08fcb6e elementor-widget elementor-widget-heading" data-id="08fcb6e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a href="#" @click="overlay = true" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Scan</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col style="padding: 1px;">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d7c2c7a" data-id="d7c2c7a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-5f80995 elementor-view-default elementor-widget elementor-widget-icon" data-id="5f80995" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="`tel:${company.phone_m}`">
			<i aria-hidden="true" class="fas fa-phone-alt" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-2432e95 elementor-widget elementor-widget-heading" data-id="2432e95" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a :href="`tel:${company.phone_m}`" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Direct Call</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col v-if="event_value">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-5b2a404" data-id="5b2a404" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-57f7405 elementor-view-default elementor-widget elementor-widget-icon" data-id="57f7405" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="event_value" target="_blank">
			<i aria-hidden="true" class="fas fa-calendar-check"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-eb196fc elementor-widget elementor-widget-heading" data-id="eb196fc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a :href="event_value" target="_blank">Events</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    </v-row>
							</div>
          </div>
          <section class="elementor-section elementor-top-section elementor-element elementor-element-a14cd74 elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="a14cd74" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;video&quot;,&quot;background_video_link&quot;:&quot;https:\/\/player.vimeo.com\/video\/68384616?h=b4d1b9c66e&quot;,&quot;background_play_on_mobile&quot;:&quot;yes&quot;,&quot;shape_divider_bottom&quot;:&quot;triangle&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;,&quot;_ha_eqh_enable&quot;:false}" :style="company.background ? 'background-image: url('+background+'); background-size: cover;  background-position: center;-repeat: no-repeat;' : ''">
								<div class="elementor-background-video-container" data-vimeo-initialized="true" v-if="company.video_background && company.video_background != 'null'">
													<div class="elementor-background-video-embed"></div>
											</div>
					<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column-header elementor-col-100 elementor-top-column elementor-element elementor-element-1d6f932" data-id="1d6f932" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-09cffa4 elementor-widget elementor-widget-image" data-id="09cffa4" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
															<img :src=" base_url_img+company.avatar" class="attachment-full size-full wp-image-689" alt="" decoding="async" loading="lazy"  sizes="(max-width: 600px) 100vw, 600px" width="600" height="600" >															</div> 
        
				</div>
					</div>
          <v-fab-transition><v-btn
        :color="'blue'"
        fab
        small
        dark
        top
        @click="$router.push('/login')"
        left
        absolute
        class="v-btn--example"
      >
        <v-icon class="material-icons-round">login</v-icon>
      </v-btn></v-fab-transition>
          <v-fab-transition><v-btn
        :color="'green'"
        fab
        small
        dark
        top
        @click="shareLink"
        right
        absolute
        class="v-btn--example"
      >
        <v-icon class="material-icons-round">share</v-icon>
      </v-btn></v-fab-transition>
		</div>
							</div>
     
		</section>
    <v-row style="display: flex; justify-content: space-around;margin:0px!important;" >
                <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: 100px; " >
                  <h1 class="mb-1 text-h4 text-typo font-weight-bold">{{company.first_name.toUpperCase()}} {{ company.last_name.toUpperCase() }} </h1>
                  
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p class="font-weight-bold text-body text-sm" style="color: black;margin-top: -15px;">{{company.roles[0].description}}</p>
                  </v-col>
                  <!-- <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <p class="font-weight-light text-body text-sm" style="color: black;margin-top: -35px;">@Round Tables LLC</p>
                  </v-col> -->
                  <v-col cols="12"  style="display: flex;margin-top: -25px; justify-content: space-around; margin-bottom: 25px;" >
                    <div>
                    <i aria-hidden="true" v-if="checkSocial('website') && company.website" class="fas fa-globe mr-5" @click="gotoLinks(company.website)" style="cursor: pointer; color:#00cc66"></i>
                    <i aria-hidden="true" v-if="checkSocial('facebook') && company.facebook" class="fab fa-facebook mr-5" @click="gotoLinks(company.facebook)" style="cursor: pointer; color:#1178F2"></i>
                    <i aria-hidden="true" v-if="checkSocial('instagram') && company.instagram" class="fab fa-instagram mr-5" @click="gotoLinks(company.instagram)" style="cursor: pointer; color:#C61FA1"></i>
                    <i aria-hidden="true" v-if="checkSocial('tiktok') && company.linkedin" class="fab fa-tiktok mr-5" @click="gotoLinks(company.linkedin)" style="cursor: pointer; color:black"></i>
                    <i aria-hidden="true" v-if="checkSocial('email') && company.email" class="fas fa-envelope mr-5" @click="sendEmail(company.email)" style="cursor: pointer;color:#EB4132"></i>
                    <i aria-hidden="true" v-if="checkSocial('youtube') && company.youtube" class="fab fa-youtube" @click="gotoLinks(company.youtube)" style="cursor: pointer;color:#EB4132"></i>
                    </div>
                  </v-col>
                  <v-col cols="12" style="display: flex; justify-content: space-around" >
                    <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-6a7f6f5 elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="6a7f6f5" data-element_type="widget" id="sales-agent-count-display" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                              <h3 class="text-h5 text-typo font-weight-bold">{{sales_agent_count}}
                                </h3>		</div>
                                </div>
                                <div class="elementor-element elementor-element-4c0467a elementor-widget elementor-widget-heading" data-id="4c0467a" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                 <h3 class=" text-h6 text-typo">Sales Agent(s)</h3>		</div>
                                </div>
                    </div>
                    <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-6a7f6f5 elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading" data-id="6a7f6f5" data-element_type="widget" id="sales-agent-count-display" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                              <h3 class="text-h5 text-typo font-weight-bold">{{ref_agent_count}}
                                </h3>		</div>
                                </div>
                                <div class="elementor-element elementor-element-4c0467a elementor-widget elementor-widget-heading" data-id="4c0467a" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                 <h3 class="text-h6 text-typo">Referring Agent(s)</h3>		</div>
                                </div>
                    </div>
                  </v-col>
                </v-row>
  
        <v-row style="justify-content: center;margin:0px!important;">
          
              
              <v-col cols="12" md="12" style="margin-bottom: 100px !important;margin-top: 15px;">
                <!-- <v-btn
              
                rounded
                v-if="checkPermission('sales')"
                :color="getStyle('button')"
                @click="$router.push('/form-sales/'+company.user_id)"
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                large
              ><v-icon class="material-icons-round" left>
                business_center
                  </v-icon>Become a sales agent
              </v-btn> -->
              <v-btn
              class="mt-3"
                rounded
                :color="getStyle('button')"
                v-if="checkPermission('ref')"
                @click="$router.push('/form-ref/'+company.user_id)"
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                large
              ><v-icon class="material-icons-round" left>
                person
                  </v-icon>Become a Referring agent
              </v-btn>
              
              <v-btn
              class="mt-3"
                rounded
                :color="getStyle('button')"
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                @click="viewInventory"
                large
              >View Inventory
              </v-btn>
              <v-btn
                rounded
                class="mt-3"
                :color="getStyle('button')"
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                @click="addCard()"
                large
              >Add to contacts
              </v-btn>
              <v-btn
              class="mt-3"
                rounded
                :color="getStyle('button')"
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                @click="gotoLinks('https://roundtables.mycarsonline.com/get-pre-qualified')"
                large
              >Get Pre Approved! <img  :height=" 50" src="@/assets/img/roundtable.png">
              </v-btn>
              <v-btn
                block
                rounded
                v-if="checkPermissionPurchaseProgram()"
                style="text-transform:none;"
                color="#1B263B"
                class="mt-3"
                :dark="true"  
                large             
                @click="gotoLinks(company_settings.purchase_program_link)"
              >AutoSyncX™️ Purchase Support Program
              </v-btn>
            </v-col>

            <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: -80px;" >
                  <img :height="this.$vuetify.breakpoint.xs ? 200 : 200" class="image-logo2" src="@/assets/img/logo.png">
            </v-col>
            <v-col cols="12" md="12" style="margin-bottom: 30px !important;margin-top: 15px;">
              
              <v-btn
              class="mt-3"
                rounded
                :color="getStyle('button')"
                dark
                block
                @click="callNumber(company.phone_m)"
                large
              ><v-icon class="material-icons-round" left>
                call
                  </v-icon>Direct call
              </v-btn>
              <v-btn
                rounded
                class="mt-3"
                :color="getStyle('button')"
                
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                large
              ><v-icon class="material-icons-round" style="font-size: 25px;" left @click="openInGoogleMaps(company.business_address)">
                location_on
                  </v-icon>Meet up<v-icon class="material-icons-round" style="font-size: 25px;" right @click="gotoVideo">
                video_call
                  </v-icon>
              </v-btn>
            </v-col>
            </v-row>
            <v-row style="justify-content: center;margin-bottom: 150px;margin: 0;">
              
              <!-- <v-col cols="12" md="12" style="">
                <Query :color="color" />
              </v-col> -->
              <!-- <v-col cols="12" md="12" style="margin-bottom: 10px !important;margin-top: 15px;">
              <v-btn
                rounded
                :color="getStyle('button')"
                @click="$router.push('/form-sales/'+company.user_id)"
                :dark="vcard.header_color=='Light Text' ? false : true"
                block
                large
              ><v-icon class="material-icons-round" left>
                business_center
                  </v-icon>Become a sales agent
              </v-btn>
              <h1 class="text-h6 mt-3 text-center">OR</h1>
              
            </v-col> -->
            <v-col cols="12" md="12" style="margin-bottom: 40px !important;">
              <h1 class="text-h6 mt-3 text-center">Powered by <strong>AutoSync</strong></h1>
            </v-col>
            </v-row>
            <v-row style="justify-content: center;margin-bottom:50px!important;"></v-row>

                  <v-dialog
                      v-model="overlay"
                      width="500"
                    >
                    <img v-if="company"  class="" :src="base_url_img+'vcard/'+company.user_id+'-QR.png'" width="50">
                  
                  
                  </v-dialog>
                  <v-dialog
                      v-model="overlay_inventory"
                      width="500"
                    >
                    
                    <div style="padding: 10!important;" >
                    <Query class="mt-5" :color="color" :ref_id="company.user_id" />
                    </div>
                    </v-dialog>
           
    </v-main>


    <!-- Reps View-->
    <v-main class="auth-pages" v-else >
    
        <div class="footer" id="myHeader" :style="'background-color:#272727'">
          <div class="elementor-container elementor-column-gap-default" style="width: 100%;">
            <v-row style="margin:0px!important;">
    <v-col style="padding: 1px;">
        <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-a828a20" data-id="a828a20" data-element_type="column">
    <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-88f37c7 elementor-view-default elementor-widget elementor-widget-icon" data-id="88f37c7" data-element_type="widget" data-widget_type="icon.default">
      <div class="elementor-widget-container">
        <div class="elementor-icon-wrapper">
    <a class="elementor-icon" href="tel:6026930899">
    <i aria-hidden="true" class="fa fa-id-card" :style=" 'color:white!important'"></i>			</a>
    </div>
      </div>
      </div>
      <div class="elementor-element elementor-element-f383dda elementor-widget elementor-widget-heading" data-id="f383dda" data-element_type="widget" data-widget_type="heading.default">
      <div class="elementor-widget-container">
    <h6 class="elementor-heading-title-card elementor-size-default" ><a href="tel:6026930899" :style=" 'color:white!important'">Card</a></h6>		</div>
      </div>
        </div>
    </div>
    </v-col>
    <v-col style="padding: 1px;">
      <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1eed04c" data-id="1eed04c" data-element_type="column" id="share-my-card" @click="overlay = true">
    <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-61dd731 elementor-view-default elementor-widget elementor-widget-icon" data-id="61dd731" data-element_type="widget" data-widget_type="icon.default">
      <div class="elementor-widget-container">
        <div class="elementor-icon-wrapper">
    <a class="elementor-icon" href="#">
    <i aria-hidden="true" class="fa fa-qrcode" :style=" 'color:white!important'"></i>			</a>
    </div>
      </div>
      </div>
      <div class="elementor-element elementor-element-08fcb6e elementor-widget elementor-widget-heading" data-id="08fcb6e" data-element_type="widget" data-widget_type="heading.default">
      <div class="elementor-widget-container">
    <h6 class="elementor-heading-title-card elementor-size-default"><a href="#" @click="overlay = true" :style="'color:white!important'">Scan</a></h6>		</div>
      </div>
        </div>
    </div>
    </v-col>
    <v-col style="padding: 1px;">
      <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d7c2c7a" data-id="d7c2c7a" data-element_type="column">
    <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-5f80995 elementor-view-default elementor-widget elementor-widget-icon" data-id="5f80995" data-element_type="widget" data-widget_type="icon.default">
      <div class="elementor-widget-container">
        <div class="elementor-icon-wrapper">
    <a class="elementor-icon" :href="`tel:${company.phone_m}`">
    <i aria-hidden="true" class="fas fa-phone-alt" :style="'color:white!important'"></i>			</a>
    </div>
      </div>
      </div>
      <div class="elementor-element elementor-element-2432e95 elementor-widget elementor-widget-heading" data-id="2432e95" data-element_type="widget" data-widget_type="heading.default">
      <div class="elementor-widget-container">
    <h6 class="elementor-heading-title-card elementor-size-default"><a :href="`tel:${company.phone_m}`" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Direct Call</a></h6>		</div>
      </div>
        </div>
    </div>
    </v-col>
    <v-col v-if="event_value">
      <div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-5b2a404" data-id="5b2a404" data-element_type="column">
    <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-57f7405 elementor-view-default elementor-widget elementor-widget-icon" data-id="57f7405" data-element_type="widget" data-widget_type="icon.default">
      <div class="elementor-widget-container">
        <div class="elementor-icon-wrapper">
    <a class="elementor-icon" :href="event_value" target="_blank">
    <i aria-hidden="true" class="fas fa-calendar-check"></i>			</a>
    </div>
      </div>
      </div>
      <div class="elementor-element elementor-element-eb196fc elementor-widget elementor-widget-heading" data-id="eb196fc" data-element_type="widget" data-widget_type="heading.default">
      <div class="elementor-widget-container">
    <h6 class="elementor-heading-title-card elementor-size-default"><a :href="event_value" target="_blank">Events</a></h6>		</div>
      </div>
        </div>
    </div>
    </v-col>
    </v-row>
            </div>
        </div>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-a14cd74 elementor-section-height-min-height elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="a14cd74" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;video&quot;,&quot;background_video_link&quot;:&quot;https:\/\/player.vimeo.com\/video\/68384616?h=b4d1b9c66e&quot;,&quot;background_play_on_mobile&quot;:&quot;yes&quot;,&quot;shape_divider_bottom&quot;:&quot;triangle&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;,&quot;_ha_eqh_enable&quot;:false}" :style="company.background ? 'background-image: url('+background+'); background-size: cover;  background-position: center;-repeat: no-repeat;' : ''">
              <div class="elementor-background-video-container" data-vimeo-initialized="true" v-if="company.video_background && company.video_background != 'null'">
                        <div class="elementor-background-video-embed"></div>
                    </div>
        <div class="elementor-container elementor-column-gap-default">
        <div class="elementor-column-header elementor-col-100 elementor-top-column elementor-element elementor-element-1d6f932" data-id="1d6f932" data-element_type="column">
    <div class="elementor-widget-wrap elementor-element-populated">
              <div class="elementor-element elementor-element-09cffa4 elementor-widget elementor-widget-image" data-id="09cffa4" data-element_type="widget" data-widget_type="image.default">
      <div class="elementor-widget-container">
                            <img src="@/assets/img/logo-avatar.jpg" class="attachment-full size-full wp-image-689" alt="" decoding="async" loading="lazy"  sizes="(max-width: 600px) 100vw, 600px" width="600" height="600" >															</div>
      </div>
        </div>
    </div>
            </div>
    </section>
    <v-row style="display: flex; justify-content: space-around;margin:0px!important;" >
              <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: 100px; " >
                <h1 class="mb-1 text-h4 text-typo font-weight-bold"> AutoSync Representatives </h1>
                </v-col>
                <v-col cols="12" style="display: flex; justify-content: space-around" >
                  <p class="font-weight-bold text-body text-sm" style="color: black;margin-top: -15px;">Total Representatives: {{ reps_users.length }}</p>
                </v-col>
                <v-col
                      cols="12"
                      md="6"
                    >
                    
                        <v-text-field
                            color="#e91e63"
                            label="Search Representative"
                            ref="name"
                            v-model="search_rep"
                            class="font-size-input input-style"
                          >
                          </v-text-field>
                    </v-col>
              </v-row>
          <v-row style="justify-content: center;margin-bottom: 150px;margin: 0;">
            <v-col cols="12" md="12" style="">
              <ProfileCards :users="filteredUsers" />
            </v-col>
          <v-col cols="12" md="12" style="margin-bottom: 40px !important;">
            <h1 class="text-h6 mt-3 text-center">Powered by <strong>AutoSync</strong></h1>
          </v-col>
          </v-row>
          <v-row style="justify-content: center;margin-bottom:50px!important;"></v-row>

                <v-dialog
                    v-model="overlay"
                    width="500"
                  >
                  <img v-if="company"  class="" :src="base_url_img+'vcard/'+company.user_id+'-QR.png'" width="50">
                
                
                </v-dialog>
                
        
    </v-main>
    
  </v-app>
</template>
<script>
var slides = document.querySelectorAll(".slide");
var currentSlide = 0;


import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import VCard from 'vcard-creator'
import { FadeTransition } from "vue2-transitions";
import Query from "@/components/Forms/Query.vue";
import ProfileCards from "@/components/Forms/ProfileCards.vue";
export default {
  
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ProfileCards,
    VCard,
    Query
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      sales_agent_count: 0,
      overlay_inventory : false,
      search_rep: '',
      ref_agent_count: 0,
      phone : '',
      loader : true,
      background: '',
      vimeo_account: '',
      vimeo_id : '',
      reps_flag : false,
      company_settings : '',
      reps_users : [],
      overlay : false,
      index: 0,
      showPassword : false,
      zoom_value : '',
      base64Image :'',
      avatar_mimeType: '',
      event_value :'',
      dialog: false,
      color : '',
      videos : [],
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      items: [
        ],
      company : '',
      vcard : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL,
      pageTitle: ''
    };
  },
  beforeCreate: function () {
    
},
created: function () {
      const domain = this.getSubdomain();
      this.getSettings()
      if(domain)
        this.getCompanyInfo(domain)
      else
        this.$router.push('/login');
        

},
mounted : function () {

},
methods: {
  prevSlide(index) {
  if(index !=0 )
    this.index = index - 1;
  else
    this.index = this.videos.length - 1;

},
setMetaTag(metaTags) {
    let headTag = document.getElementsByTagName('head')[0]
      for (const i in metaTags) {
        // First, delete the meta tags.
        let oldTag = document.querySelector(`meta[name="${metaTags[i].name}"]`)
        if (oldTag) {
          document.querySelector(`meta[name="${metaTags[i].name}"]`).remove()
        }
        // Second, insert the replacement meta tags.
        let newTag = document.createElement('meta')
        newTag.setAttribute('name', metaTags[i].name)
        newTag.setAttribute(metaTags[i].name, metaTags[i].content)
        headTag.appendChild(newTag)
      }
    },

nextSlide(index) {
  if(index != this.videos.length - 1 )
    this.index = index + 1
  else
    this.index = 0
},
gotopayemnt: function () {
    let self = this;
    self.$router.push('/card-payment/'+this.$route.params.id);
},
checkSocial(social){
  if(!this.company.social_flags)
    return true;
  let social_flags = JSON.parse(this.company.social_flags);
  if(social_flags[social] == 1)
    return true;
  return false;
},
getSubdomain() {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    
    // Assuming the domain is of the form subdomain.example.com
    if (domainParts.length > 2) {
        return domainParts[0];
    } else {
        return null; // No subdomain
    }
}
,
 getCompanyInfo(id){
  
  axios({
          method: "POST",
           
          url: "/api/get-card",
          data: {
            id: id
          }
   
        })
          .then(async(response) => {
            if(response.data.flag == 1){
              if(id == 'reps'){
                
                this.reps_users = response.data.user;
                this.reps_flag = true;
                this.loader = false

              }else{
                this.company = response.data.user;
                this.vcard = response.data.vcard[0];
                this.sales_agent_count = response.data.sales_agents
                this.ref_agent_count = response.data.ref_agents
                // this.background =   this.base_url_img+'vcard/'+this.company.background
                // this.company.items = JSON.parse(JSON.parse(this.company.items))
                if(this.company.video_background){
                  let video_back = this.company.video_background.split("/");
                  this.vimeo_id = video_back[0]
                  this.vimeo_account = video_back[1]
                }
                this.loader = false
                let url = process.env.VUE_APP_WASBI_URL.replace('/logo','');
                const{base64Image, mimeType} = await this.convertImageToBase64(this.base_url_img+this.company.avatar);
                this.base64Image = base64Image
                this.avatar_mimeType = mimeType
              }
            }else{
              this.$router.push('/login');
            }
          })

},
async copyURL() {
  console.log(window.location);
      try {
        await navigator.clipboard.writeText(window.location);
        alert('URL Copied, Now you can share the URL');
      } catch($e) {
        alert('Cannot copy');
      }
    },
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
getStyle(flag){
  let self = this;
  let color = this.vcard.color;
  let font = this.vcard.header_color;
  let color_code = '';
  const colorArray = [
  { name: 'Orange', code: '#FD7E14' },
  { name: 'Green', code: '#28A745' },
  { name: 'Purple', code: '#6F42C1' },
  { name: 'Red', code: '#DC3545' },
  { name: 'Pink', code: '#E83E8C' },
  { name: 'Blue', code: '#007BFF' },
  { name: 'Dark Blue', code: '#0033cc' },
  { name: 'Black', code: '#000000' },
  { name: 'Silver', code: '#C0C0C0' },
  { name: 'Hot Pink', code: '#FF69B4' },
  { name: 'Flat Black', code: '#2D3436' }
];
colorArray.forEach(element => {
  if(element.name == color){
    color_code = element.code;
    self.color = color_code;
  }
});
if(flag == 'footer'){
  return 'background-color:'+color_code;
}
if(flag == 'button'){
  return color_code;
}

},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
  callNumber(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    sendEmail(email) {
      window.location.href = `mailto:${email}`;
    },
    gotoLinks(link){
      if(!link)
      link = '#'
      const newTab = window.open('', '_blank');
      newTab.location.href = link;
    },
    shareLink() {      
      const url = window.location.origin+'/form-ref/'+this.company.user_id
      if (navigator.share) {
          navigator
            .share({
              title: "Signup to AutoSync™",
              text: "Become a Referral Agent",
              url: url
            })
            .then(() => console.log("thanks for share"))
            .catch(error => console.log("error", error));
        }
    },
    addContact() {
      const contact = {
        name: "John Doe",
        phone: "123-456-7890",
        email: "johndoe@example.com",
        address: "1234 Example St, Anytown, USA",
      };

      if ('contacts' in navigator && 'ContactsManager' in window) {
        navigator.contacts.select(
          ['name', 'email', 'tel', 'address'],
          { multiple: false }
        ).then((contacts) => {
          const newContact = new Contact({
            name: [contact.name],
            tel: [{ value: contact.phone }],
            email: [{ value: contact.email }],
            address: [{ value: contact.address }],
          });
          newContact.save();
        }).catch((error) => {
          console.error('Error adding contact:', error);
        });
      } else {
        alert(`Name: ${contact.name}\nPhone: ${contact.phone}\nEmail: ${contact.email}\nAddress: ${contact.address}`);
      }
    },
    openInGoogleMaps(address) {      
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.company_settings.business_address)}`;
      window.open(googleMapsUrl, '_blank');
    },
    gotoVideo() {
      const googleMapsUrl = this.company_settings.video_meeting_link;
      window.open(googleMapsUrl, '_blank');
    },
    getSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.company_settings = data.settings
               localStorage.setItem('settings', JSON.stringify(data.settings));
               if(data.settings.backgroung_logo)
                localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
              else
                localStorage.setItem('admin_bg','');
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });

     },
     checkPermission(flag) {
     
      if(flag == 'sales'){
        let status = 0;
        if(this.company_settings.sales_rep_flag == 'None'){
          return false
        }
        else if(this.company_settings.sales_rep_flag == 'All'){
          return true
        }else{
          if(this.company_settings.sales_rep_accounts.length != 0){
          this.company_settings.sales_rep_accounts.forEach(val => {
            if(val == this.company.user_id){
              status = 1;
            }
            
          });
        }
          return status
        }
      }
      if(flag == 'ref'){
        let status_ref = 0;
        if(this.company_settings.ref_rep_flag == 'None'){
          return false
        }
        else if(this.company_settings.ref_rep_flag == 'All'){
          return true
        }else{
          this.company_settings.ref_rep_accounts.forEach(val => {
            if(val == this.company.user_id){
              status_ref = 1;
            }
            
          });
          return status_ref
        }
      }
     },
     checkPermissionPurchaseProgram(flag) {
        let status = 0;
        if(this.company_settings.display_purchase_program == 'None'){
          return false
        }
        else if(this.company_settings.display_purchase_program == 'All'){
          return true
        }else{
          if(this.company_settings.display_purchase_program_custom.length != 0){
          this.company_settings.display_purchase_program_custom.forEach(val => {
            if(val == this.company.user_id){
              status = 1;
            }
            
          });
        }
          return status
        }
     },
  exportToFile(card, name='contact', force=true) {
            const a = document.createElement('a');
            const file = new Blob([card], { type: 'text/vcard'});

            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();

            URL.revokeObjectURL(a.href);
  },
  viewInventory(){
    
    this.$router.push('/inventory-filter')
   
  },
  getIcon(id,value){
    if(id == 'Phone'){
      this.phone = value
      return 'fa fa-phone';
    }
    if(id == 'Zoom'){
      this.zoom_value = value
      return 'fas fa-video';
    }
    if(id == 'Event'){
      this.event_value = value
      return 'fas fa-calendar-check';
    }
    if(id == 'Email')
      return 'fas fa-envelope';
    if(id == 'Whatsapp')
      return 'fab fa-whatsapp';
    if(id == 'SMS/Text')
      return 'fas fa-comments';
    if(id == 'Website')
      return 'fas fa-link';
    if(id == 'Instagram')
      return 'fab fa-instagram';
    if(id == 'Snapchat')
      return 'fab fa-snapchat';
    if(id == 'Twitter')
      return 'fab fa-twitter';
    if(id == 'Facebook')
      return 'fab fa-facebook';
    if(id == 'LinkedIN')
      return 'fab fa-linkedin';
    else
      return 'fas fa-link';
  },
      // Function to convert image URL to Base64
  async convertImageToBase64(imageUrl) {
    let self = this;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const mimeType = blob.type; // Get the MIME type of the image
    // this.avatar_mimeType = mimeType;
    // this.base64Image = blob
    // const reader = new FileReader();
    //     reader.onloadend = () => { self.base64Image = reader.result.split(',')[1]; self.avatar_mimeType = mimeType }; // Get Base64 string without the prefix
    //     reader.onerror = reject;
    //     reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve({ base64Image: reader.result.split(',')[1], mimeType }); // Get Base64 string without the prefix
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
},
/**
 * checkForm
 * login the user with valid email/pass
 */
async addCard(value) {
            const myVCard = new VCard()
            myVCard.addName(this.company.first_name+' '+this.company.last_name)
            if(this.company.business_name)
              myVCard.addCompany(this.company.business_name)
            myVCard.addEmail(this.company.email)
            myVCard.addPhoneNumber(this.company.phone_m)     
            
            // Add social media links if available
            if (this.company.facebook) {
                myVCard.addSocial(this.company.facebook, 'Facebook');
            }
            if (this.company.instagram) {
                myVCard.addSocial(this.company.instagram, 'Instagram');
            }
            if (this.company.linkedin) {
                myVCard.addSocial(this.company.linkedin, 'TikTok');
            }
            if (this.company.website) {
                myVCard.addSocial(this.company.website, 'Website');
            }
            if (this.company.youtube) {
                myVCard.addSocial(this.company.youtube, 'YouTube');
            }
            if (this.company.avatar) {
              if (this.avatar_mimeType == 'image/png') {
                myVCard.addPhoto(`${this.base64Image}`, 'PNG');
              }
              else if (this.avatar_mimeType == 'image/jpeg') {
                myVCard.addPhoto(`${this.base64Image}`, 'JPEG');
              }
              else if (this.avatar_mimeType == 'image/gif') {
                myVCard.addPhoto(`${this.base64Image}`, 'GIF');
              }
            }
            const output = myVCard.toString()
            const preElement = document.querySelector('#output')
            const outputNode = document.createTextNode(output)
            this.exportToFile(output,'contact',true)
          
    }
  },

  computed: {
    filteredUsers() {
      const query = this.search_rep.toLowerCase();
      return this.reps_users.filter(user => {
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        return fullName.includes(query);
      });
    }
  },
  watch: {
    pageTitle() {
      this.$meta().refresh();
    },
  },
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
.header-card{
  overflow: hidden;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid rgb(217, 218, 220);
  color: rgb(20, 20, 20);
  background: linear-gradient(45deg, rgb(255, 117, 140), rgb(255, 126, 179));
}
.cards{
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 80px 10px 30px;
  background-color: rgb(230, 230, 230);
  border-bottom: 1px solid rgb(217, 218, 220);
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: rgb(20, 20, 20);
}
.cards:nth-child(2n+1) {
  border-right: 1px solid rgb(217, 218, 220);
}
.cards:hover {
  color: skyblue!important;
  background: #D9E6F4;
}
.header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: solid #fff;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
}
.footer {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border: 0px;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
  width: 100%;
}
.elementor-container {
text-align: center;
}
.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
}
.elementor-column-header {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
  margin-bottom: -170px;
  margin-top: 50px;
}
.elementor-heading-title-card{
  color: #FFFFFF;
  font-family: "Montserrat", Sans-serif;
  font-weight: 800;
  text-shadow: 4px 4px 10px rgba(0,0,0,0.3);
}
.elementor-icon-wrapper{
  font-size: 25px;
  color: #000000;
}
.elementor-element.elementor-element-09cffa4 img {
  width: 200px;
  height: 200px;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #FFFFFF;
  border-radius: 300px 300px 300px 300px;
  
}
.elementor-element.elementor-element-a14cd74:not(.elementor-motion-effects-element-type-background), .elementor-581 .elementor-element.elementor-element-a14cd74 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
  background: url("https://s3.us-central-1.wasabisys.com/quantumresolution/logo/david-bg.jpg") 50% 50%;
    background-size: auto;
  background-size: cover;
  padding: 60px 0px 70px 0px;
  position: relative;
}
.elementor-shape-bottom {
  bottom: -1px;
}
.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}
.elementor-heading-title2 {
  color: #303030;
  font-family: "Lora", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
 .elementor-social-icon {
  background-color: #021033;
}
.elementor-icon.elementor-social-icon {
  border-radius: 50%;
  font-size: 15px;
  line-height: 25px;
  width: 25px;
  display: inline-block;
  
  height: 25px;
}
.elementor-screen-only, .screen-reader-text, .screen-reader-text span, .ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.elementor-widget-container {
  text-align: center;
}
.elementor-social-icon i {
  color: #FFFFFF;
}
.elementor-grid-item{
  margin: 5px;
}
.slider {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  visibility: visible;
  opacity: 1;
}

iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container1 {
  display: flex;
  align-items: center;
  gap: 8px;
}
 .elementor-button {
  display: block;
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
  fill: var( --e-global-color-6aef213 );
  color: var( --e-global-color-6aef213 );
  background-color: #FEFEFE;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: var( --e-global-color-6aef213 );
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.32);
  padding: 14px 50px 14px 50px;
}

.elementor-background-video-container {

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
    transition: opacity 1s;
    pointer-events: none;

}
 .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>